<template>
  <div class="nav">
    <div class="nav__wrap">
      <ul>
        <li
          v-for="(item,index) in navList"
          :key="index"
          @click="goHome(item)"
        >
          <span>{{ item.name }}</span>
          <template v-if="item.children">
            <ul>
              <li
              v-for="(child,j) in item.children"
              :key="j"
              @click="routerGo(child)"
              >
              <span>{{ child.name }}</span>
            </li>
            </ul>
          </template>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import './index.scss'
export default {
  data() {
    return {
      navList: [
        {
          name: 'الخدمات على الإنترنت',
          children: [
            {name: 'قاعة التسجيل'},
            {name: 'دليل المؤتمر'},
            {name: 'المعلومات الأساسية عن المعارض'},
            {name: 'الخدمات'},
            {name: 'شركاء التعاون'}
          ]
        },
        {
          name: 'النقاش على الإنترنت',
          children: [
            {name: 'دليل الاستثمار'}
          ]
        },
        {
          name: 'زيارة المعارض على الإنترنت',
          children: [
            {name: 'المعرض على الإنترنت'},
            {name: 'دليل المعرض'}
          ]
        },
        {
          name: 'لاجتماعات والمنتديات',
          children: [
            {name: 'لاجتماعات والمنتديات'},
            {name: 'جدول الأعمال'}
          ]
        },
        {
          name: 'مركز الإعلام',
          children: [
            {name:'المعلومات الأساسية للمعرض',route: 'summarize'},
            {name: 'الفيديوهات الرائعة', route: 'videolist'},
            {name: 'الأخبار الساخنة', route: 'newslist'}
          ]
        },
        {
          name: 'الصفحة الرئيسية', route: 'Home'
        }
      ]
    }
  },
  mounted() {
    console.log(this.navList, '0--');
  },
  methods: {
    goHome(v) {
      if ('route' in v) {
        this.$router.push({
          name: v.route
        })
      }
    },
    routerGo(v) {
      if ('route' in v) {
        this.$router.push({
          name: v.route
        })
      }
    }
  }
}
</script>