<template>
  <div class="header">
    <div class="header-top">
      <div class="header-top__wrap">
        <div class="header-top__lang">
          <a class="active">عربي</a>
          <a @click="goHome('https://www.cas-expo.org.cn/en/index.html')">ENGLISH</a>
          <a @click="goHome('https://www.cas-expo.org.cn/zh/index.html')">中文</a>
        </div>
        <div class="header-top__right">مرحبا بكم في معرض الصين والدول العربية</div>
      </div>
    </div>
    <div class="header-bottom">
      <div class="header-bottom__wrap">
        <div class="header-bottom__left">
          التعاون في العصر الجديد والتمسك بالفرص الجديدة وتشارك المستقبل الجديد
        </div>
        <div class="header-bottom__logo">
          <img src="@/assets/img/itemLogo.png"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import './index.scss'
export default {
  methods: {
    goHome(url) {
      window.location.href = url
    }
  }
}
</script>

<style lang="scss">

</style>